// custom typefaces
import "@fontsource/montserrat/variable.css"
import "@fontsource/merriweather"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// TODO: try to remov stuff above
import React from "react"
import "./styles/main.scss"
import "./styles/fonts.css"

export const wrapRootElement = ({ element }) => <>{element}</>
